*{
  margin: 0;
  padding: 0;
}

body{
  background-color: black;
  
}



  @import url("https://use.typekit.net/sof1stj.css");



.header{
  width: 100%;
  color: white;
  display: grid;
  grid-template-columns: 10% 90%;
  background-color: white;
  padding-bottom: 30px;


  
  
}

@media screen and (max-width:867px) {
  .header{
    grid-template-columns: auto;
  }

}

.header__list-container{
  display: grid;
  grid-template-columns:  auto auto auto;
  align-items: center;
  list-style-type: none;
  padding: 0;
}

.header__list {
  list-style: none;
  font-size: 26px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  border: 0;
  border-bottom: 3px solid transparent;
  transition: border-color 0.8s ease in; /* Transición más lenta */
  color:black;
  text-align: center;
}

@media screen and (max-width:867px) {
  .header__list {
    font-size: 18px;
  }
}

@media screen and (max-width:524px) {
  .header__list{
    font-size: 12px;
  }
  
}

.header__list:hover {
  border-bottom-color: red;
  animation: textAnimation 0.8s ease forwards;
  transition: 2s ease; /* Agrega animación al texto */
}



@keyframes textAnimation {
  0% {
    transform: translateX(0);
  
  }
  100% {
    transform: translateX(5px);

  }
}



.header__logo-container{
  width: 100%;
}

.header__logo{
  width: 100%;
  animation: spin 4s infinite linear;

}

@keyframes spin {
  from {
      transform: rotateY(0deg);
  }
  to {
      transform: rotateY(-360deg);
  }
}

@media screen and (max-width:867px) {
 
  .header__logo{
    width: 100%;
  }
}


.mainBanner{
  width:100%;
  margin: 0 auto;
  border-top: transparent ;
 



}
.mainImage{
  width: 100%;
}

.welcome {
  background-image: url(./images/Shutterstock_2273662569.jpg);
  background-repeat: no-repeat;
  background-size: cover;
width: 100%;

}


.welcome__grid-container{
  display: grid;
  grid-template-columns: 40% 60%;
  background-color: rgba(0, 0, 0, 0.6 );
  width: 100%;
  justify-content: center;

}

@media screen and (max-width:1024px) {
  .welcome__grid-container{
    grid-template-columns: auto;
  }
  
}

.welcome__text{
  color: white;
  padding-top: 16px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  text-align: left;
  width: 80%;
}

.welcome__text-title{
  color: white;
  font-size: 90px;
  text-align: left;
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 80%;

}

@media screen and (max-width:867px) {
  .welcome__text-title{
    font-size: 45px;
  }
  
}

@media screen and (max-width:320px) {
  .welcome__text-title{
    font-size: 30px;
  }
  
}

.welcome__text-container{
  display: grid;
  grid-template-rows: auto auto auto;
  width: 100%;
}


.welcome__image-container{
width: 100%;
display: grid;

gap: 25px;
}


.welcome__button-container{
  width: 100%;
  display: flex;
  padding-top: 30px;
}

.welcome__button{
  width: 30%;
  height: 50px;
  color: white;
background-color: red ;
  box-shadow: transparent;
 border-radius: 5px;
font-size: 17px;
font-family: "futura-pt", sans-serif;
font-weight: 400;
font-style: normal;
animation: bounce 0.5s infinite alternate;
user-select: none;
text-shadow: none;
transition: 1s ease;
}



.welcome__image{
 width: 100%;

 
}

@media screen and (max-width:867px) {

  .welcome__image{
    width: 100%;
  
  }
  
}



@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
  0%{
    transform: translateY(5);
  }
}

.welcome__button:hover,
.welcome__button:focus {
  color: #ffffff;
  background: red;
  border: 1px solid red;
  text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 20px #ffffff;
  box-shadow: 0 0 5px red, 0 0 20px red, 0 0 50px red, 0 0 100px red;
  border-color: transparent;
  font-size: 16px;
}


.promotions{
  width: 100%;
  padding-top: 100px;
  background-color: white;
  padding-bottom: 100px;


}

.promotions__text{
  padding-bottom: 30px;
}

@media screen and (max-width:867px) {

  .promotions{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
}

.promotions__title{
  color: black;
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  text-align: center;
}

@media screen and (max-width:867px) {
  .promotions__title{
    font-size: 45px;
  }
  
}

@media screen and (max-width:320px) {
  .promotions__title{
    font-size: 20px;
  }
  
}

.promotions__subtitle{
  color: black;
  padding-top: 25px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  text-align: center;
  font-style: normal;
}

.promotions__scroller-container{
  max-width: 100%;

  -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  overflow:hidden;



 
}

@keyframes scroll2 {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.promotions__pictures-container{
  width: 100%;
display: flex;
  background-image: url("./images/welcomePicture9.png");
  background-size: cover;
  background-repeat: no-repeat; 
}

.promotions__pictures{
  width: max-content;
  padding-block:1rem;
  display:flex;
  animation: scroll linear 18s infinite;
  gap: 1rem;

}


.promotions__image{
  width: 400px;
  border-radius: 10px;
  cursor: pointer;
}

@media screen and (max-width:867px) {

  .promotions__image{
    width: 350px;
  }
  
}

.promotions__pictures-back_container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.promotions__pictures-back{
  width: 30%;
  height: 50px;
}
.promotions__pictures-next{
  width: 30%;
  height: 50px;
}

.promotions__pictures-next-container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tournaments{
  width: 80%;
  padding-top: 100px;
  margin: 0 auto;
  padding-bottom: 100px;
  

}

.tournaments__container{
  width: 100%; /* Ancho de la galería */
  height: 600px; /* Altura de la galería */
  overflow-y: auto; 
  
 
}

.tournaments__text-container{
  width: 100%;

}

.tournaments__title{
  color: white;
  font-size: 50px;
  padding-bottom: 50px;
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  width: 100%;
  
}

@media screen and (max-width:867px) {

  .tournaments__title{
    font-size: 30px;
  }
  
}

@media screen and (max-width:320px) {

  .tournaments__title{
    font-size: 20px;
  }
  
}

.tournaments__subtitle{
  color: white;
  font-size: 35px;
  padding-bottom: 50px; 
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media screen and (max-width:867px) {
  .tournaments__subtitle{
  
    font-size: 20px;
;
  }
}

.tournaments__promotions__container{
  width: 100%;
  display: grid;
  grid-template-columns: 15% 15% 70%;
  background-image: url("./images/welcomePicture6.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 20px;
}



@media screen and (max-width:767px) {
  .tournaments__promotions__container{
    grid-template-columns: auto;
  }
  
}

.tournaments__promotions-date{
  color: white;
  font-size: 34px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  display: grid;
  grid-template-rows: auto auto auto;
  text-align: center;
}
.tournaments__promotions-text{
  color: white;
  font-size: 24px;
  font-family: "futura-pt", sans-serif;
font-weight: 400;
font-style: normal;
  display: grid;
  grid-template-rows: auto auto auto;

  
}

.tournaments__promotions-text:hover{
 color: #ffffff;
  background: red;
  border: 1px solid red;
  text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 20px #ffffff;
  box-shadow: 0 0 5px red, 0 0 20px red, 0 0 50px red, 0 0 100px red;
  user-select: none;
  text-shadow: none;
  transition: 2s ease;
    box-shadow: transparent;
    cursor: pointer;
    font-size: 22px;
 
  
}

.tournaments__promotions-image{
  width: 100%;
}

@media screen and (max-width:767px) {
  .tournaments__promotions-image{
    width: 80%;
  margin: 0 auto;
  }
  
}

/* WebKit (Google Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color de fondo de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Color del "pulgarcito" de la barra de desplazamiento */
}


.tag-list{
  margin: 0;
  padding-inline: 0;
  list-style: none;
  color:white;
  font-size: 30px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media screen and (max-width:867px) {
  .tag-list{
    font-size: 16px;
  }
}
.tag-list li {
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem var(--clr-primary-900);

}

.scroller {
 
  max-width: 100%;
  border-bottom: 1px rgb(206, 205, 205) solid;
  -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  overflow:hidden;
  margin: 0 auto;
  display: grid;


}


.scroller-inner{
  width: max-content;
  padding-block:1rem;
  display:flex;
  animation: scroll linear 60s infinite;
  gap: 10px;


}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}


.video{
  width: 100%;
  background-color: #ffffff;
  display: grid;
  grid-template-rows: auto auto;
  

}


 

.video__container{
  width: 50%;
  display: flex;
 margin: 0 auto;
 padding-bottom: 50px;
 
}

@media screen and (max-width:867px) {
  .video__container{
    width:80%;
  }
  
}

.video__title{
  font-family: "futura-pt-bold", sans-serif;
font-weight: 700;
font-style: normal;
color: black;
font-size: 50px;
width: 100%;
text-align: center;


}

.video__subtitle{
  font-family: "futura-pt", sans-serif;
font-weight: 400;
font-style: normal;
color: black;
padding-top: 15px;
font-size: 34px;
width: 50%;
text-align: center;
}

@media screen and (max-width:867px) {
  .video__subtitle{
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
  
}

.video__text-container{

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  width: 100%;
  
  
}





.winners{
  width: 100%;
  
}

.winners__grid{
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 10px;
  

}

@media screen and (max-width:1024px) {
  .winners__grid{
    grid-template-columns: auto;
  
  }
  
}

.winners__grid-main{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.winners__grid-main-picture{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.winners__text-container{
  display: grid;
  grid-template-rows: auto auto  auto;
 
  color: white;
  margin: 0  auto;
  
 
}



.winners__title{
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: white;
  font-size: 60px;
  width: 80%;
  text-align: left;
  

}

@media screen and (max-width:1024px) {

  .winners__title{
    width: 100%;
    text-align: center;
  }
  
}

@media screen and (max-width:1024px) {

  .winners__title{
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
  
}

.winners__text{
  width: 90%;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: white;
  padding-top: 15px;
  font-size: 25px;
  text-align: left;
  
}

@media screen and (max-width:1024px) {

  .winners__text{
    width: 100%;
    text-align: center;
    padding-bottom: 25px;
  }
  
}

.winners__button-container{
  width: 100%;
  justify-content: center;
  display: flex;
  padding-bottom: 25px;
  

}

.winners__button{
  width: 30%;
  height: 50px;
  color: white;
  background-color: red ;
  box-shadow: transparent;
 border-radius: 5px;
font-size: 17px;
font-family: "futura-pt", sans-serif;
font-weight: 400;
font-style: normal;
animation: bounce 0.5s infinite alternate;
user-select: none;
text-shadow: none;
transition: 1s ease;
margin-top: 10px;
border: 1px red solid;
}


.winners__button:hover,
.winners__button:focus {
  color: #ffffff;
  background: red;
  border: 1px solid red;
  text-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 20px #ffffff;
  box-shadow: 0 0 5px red, 0 0 20px red, 0 0 50px red, 0 0 100px red;
  border-color: transparent;
  font-size: 16px;
}
.winners__grid-secondary{
  width: 100%;
  display: grid;
  grid-template-rows: auto  auto;
 margin: 0 auto;


}

.winners__grid-secondary-picture{
  width: 50%;
  min-width: 50%;
  display: flex;
margin: 0 auto;
border-radius: 10px;

}

@media screen and (max-width:1024px) {
  .winners__grid-secondary-picture{
    width: 80%;
  }
}





.food{
  width: 100%;
  background-color: white;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width:1024px) {
  .food{
    padding-bottom: 50px;
  
  }
  
}

.food__container{
  width: 100%;
  grid-template-rows: auto auto;
}



.food__main{
  display: grid;
  grid-template-columns: auto auto;
  max-width: 100%;
  padding-top: 50px;
}

@media screen and (max-width:1024px) {
  .food__main{
    grid-template-columns: auto;
    padding-top: 0;
    padding-bottom: 25px;
  }
}

@media screen and (max-width:1024px) {

  .food__main{
    gap: 25px;
  }
  
}

.food__main-picture{
  width: 80%;
  display: flex;
 margin: 0 auto;
 border-radius: 10px;

}

.food__title{
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  color: black;
  font-size: 60px;
  width: 100%;
  text-align: left;
}

@media screen and (max-width:867px) {
  .food__title{
    text-align: center  ;
    font-size: 30px;
  }
  
}

@media screen and (max-width:320px) {
  .food__title{
    text-align: center  ;
    font-size: 20px;
  }
  
}



.food__text{
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 45px;
  text-align: left;
  padding-top: 10px;
}

@media screen and (max-width:867px) {

  .food__text{
    font-size: 20px;
  }
  
}

.footer{
  width: 100%;
  background-color: black;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  align-items: center;
  padding: 10px 0 10px 0;



}

@media screen and (max-width:867px) {

  .footer{
    grid-template-columns: auto;
  
  
 
    text-align: center;

  }
  
}

@media screen and (max-width:867px) {
  .food__text{
    text-align: center  ;
  }
  
}
.footer__logo{
  width: 30%;
  animation: spin 2s infinite linear;

}

@media screen and (max-width:867px) {

  .footer__logo{
    display: flex;
 margin: 0  auto;

  }
  
}

.footer__address{
  color: white;
  text-align: center;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  width: 80%;
}

@media screen and (max-width: 867px) {
  .footer__address {
    width: 100%;
    text-align: center;
  }
}


.footer__phone{
  color: white;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.footer__media{
  width: 25%;


}


.footer__media-container{
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;

}

.footer__media-picture-container{
  display: grid;
  grid-template-columns: auto auto;
}



.footer__media-attribute{
  display: flex;
  justify-content: flex-end;
width: 100%;
}

.footer__media-attribute2{
  display: flex;
  justify-content: flex-start;
width: 100%;
}

.footer__media-title{
  color: white;
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}

.footer__documents{
  width: 100%;

}

.footer__document{
  color: white;
  list-style: none;
  text-align: center;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  cursor: pointer;
}


.header__attribute {
  text-decoration: none;
  color: inherit; /* Para heredar el color del texto del elemento padre */
  /* Otros estilos si es necesario */
}

.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color negro semi-transparente */
  z-index: 999; /* Asegura que esté por encima de todo */
  display: flex;
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-rows: auto auto;
}

.modal__container{

 

  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;


}

.modal__picture{
  width: 100%;
}

.modal__button{
  cursor: pointer;
  width: 5%;
}

@media screen and (max-width:867px) {
  .modal__button{
    width: 10%;
  }
  
}

.modal__picture-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__button-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.privacyPolicy{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: rgba(0, 0, 0, 0.5); /* Color negro semi-transparente */
  z-index: 999; /* Asegura que esté por encima de todo */
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-rows: auto auto;
 
}

.privacyPolicy__content{
  background-color: white;
  width: 80%;
  border-radius: 10px;
  margin: 0 auto;
  overflow-y: auto; 
  height: 800px;
}

.privacyPolicy__title{
  color: black;
  text-align: center;
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  padding-bottom: 10px;
  font-size: 30px;

}

.privacyPolicy__title-container{
  border-bottom: red 2px solid;
}

.privacyPolicy__list{
  color: black;
  text-align: center;
 width: 100%;
 font-size: 16px;
 font-family: "futura-pt", sans-serif;
 font-weight: 400;
 font-style: normal;
 list-style: none;
}

@media screen and (max-width:867px) {
  .privacyPolicy__list{
 
    font-size: 12px;

  }
  
}


.privacyPolicy__text{
  color: black;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding-bottom: 10px;
}

@media screen and (max-width:867px) {
  .privacyPolicy__text{
 
    font-size: 12px;

  }
  
}

.privacyPolicy__list-container{
  width: 100%;
  padding: 0; /* Esto elimina el espacio interno predeterminado de las listas */
}

.privacyPolicy__tex-title{
  text-align: center;
  font-family: "futura-pt-bold", sans-serif;
  font-weight: 700;
  font-style: normal;
  padding-bottom: 10px;
}

.privacyPolicy{
  display: grid;
  grid-template-rows: auto auto ;
}

.privacyPolicy__button-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.privacyPolicy__button{
    cursor: pointer;
  width: 3%;
}

@media screen and (max-width:867px) {
  .privacyPolicy__button{
    cursor: pointer;
  width: 10%;
}

}

.form{
  position: fixed;
  bottom: 140px;
  width: 50%;


}

@media screen and (max-width:867px) {
  .form{
   
    bottom: 100px;
    width: 100%;
  
  }
  
}




.form__container{
  width: 100%;

  

}

.iframe__container{
  width: 100%;
  display: flex;
  justify-content: center;

}

@media screen and (max-width:867px) {
  .iframe__container{
    width: 100%;

  
  }
  
}



iframe {
  border: 0;
  width: 100%;
  height: 520px;
 
}

@media screen and (max-width:867px) {
  iframe {
    border: 0;
    width: 100%;
    height: 520px;
   
  }
}


.form__title{
  color: white;
  font-size: 50px;
  text-align: center;
}

.form__subtitle{
  color: white;
  font-size: 25px;
  text-align: center;
}

.form__button{
  width: 5%;
  cursor: pointer;

}

@media screen and (max-width:867px) {
  .form__button{
    right: 1px;
    width: 10%;
  }
  
}

@media screen and (max-width:320px) {
  .form__button{
    left: 10px;
    width: 30%;
  }
  
}

.form__button-container{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}



.chat{
  width: 15%;
  position:fixed;
  bottom: 25px;
  animation: bounce2 2s infinite;
  cursor: pointer;
  display: grid;
  grid-template-rows: auto auto;
}

@media screen and (max-width:867px) {
  .chat{
    width: 20%;
  }
  
}




.chat__picture{
  width: 30%;
}

@media screen and (max-width:867px) {
  .chat__picture{
    width: 70%;
  }
  
}


.chat__text-container{
  background-color: white;
  border-radius: 10px;
  width: 100%;
top: 10px;
}

@media screen and (max-width:867px) {
  .chat__text-container{
    background-color: white;
    border-radius: 10px;
    width: 100%;
  top: 10px;
  } 
}

.chat__text{
  text-align: center;
  font-size: 24px;
  font-family: "futura-pt", sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 100%;
  

}

@media screen and (max-width:867px) {
  .chat__text{

    font-size: 12px;

    
  
  }
}




@keyframes bounce2 {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-20px);
  }
  60% {
      transform: translateY(-10px);
  }
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
    transition: all 2s ease;

}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ocupa toda la altura de la ventana */
}
